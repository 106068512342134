import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/Seo'
import Hero from '@components/Hero'
import Footer from '@components/Footer'
import Contact from '@components/Contact/Contact'

const ContactPage = ({ data }) => {
  const {
    seoTitle,
    seoDescription: { seoDescription },
    slug,
    hero,
    contactFormIntro: { contactFormIntro },
  } = data.contentfulContact

  return (
    <>
      <Seo title={seoTitle} description={seoDescription} pathname={slug} />

      <Hero data={hero} />

      <Contact intro={contactFormIntro} />

      <Footer />
    </>
  )
}

export default ContactPage

export const pageQuery = graphql`
  query ContactPage($slug: String!) {
    contentfulContact(slug: { eq: $slug }) {
      seoTitle
      seoDescription {
        seoDescription
      }
      slug
      hero {
        ...ContentfulHero
      }
      contactFormIntro {
        contactFormIntro
      }
    }
  }
`
