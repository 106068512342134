import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { ContactForm, ContactMain } from './index.style'
import Spacer from '@components/Spacer'
import { Heading4, TextBody } from '@components/TextStyles'
import AnimateSplitText from '@components/animation/AnimateSplitText'
import Container from '@components/Container'
import Grid from '@components/Grid'
import GridItem from '@components/GridItem'
import AnimateSlideIn from '@components/animation/AnimateSlideIn'
// import Select from '@components/Select'
import Input from '@components/Input'
import Textarea from '@components/Textarea'
import Button from '@components/Button'
import IconArrowRight from '@components/svgs/IconArrowRight'
import { colors } from '@styles/vars/colors.style'

const Contact = ({ intro }) => {
  const $form = useRef()
  const [formSent, setFormSent] = useState(false)
  // const [type, setType] = useState('general-information')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [number, setNumber] = useState('')
  const [message, setMessage] = useState('')
  const formName = 'contact-form'
  const nameName = 'Name'
  const emailName = 'Email address'
  const numberName = 'Phone number'
  const messageName = 'Your message'
  const subjectPrefix = 'Contact form submission from:'
  // const typeName = 'For'
  // const formTypeOptions = [
  //   {
  //     value: 'general-enquiry',
  //     label: 'General enquiry',
  //   },
  //   {
  //     value: 'report-an-incident',
  //     label: 'Report an incident',
  //   },
  // ]

  const encode = data => {
    const value = Object.keys(data).map(
      key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
    )
    return value.join('&')
  }

  const handleSubmit = e => {
    e.preventDefault()

    const data = {
      'form-name': $form.current.getAttribute('name'),
      subject: `${subjectPrefix} ${name}`,
      // [typeName]:
      //   formTypeOptions.find(option => option.value === type)?.label || '',
      [nameName]: name,
      [emailName]: email,
      [messageName]: message,
    }

    if (number.length) {
      data[numberName] = number
    }

    console.log(data)

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode(data),
    })
      .then(() => setFormSent(true))
      .catch(error => alert(error))
  }

  return (
    <ContactMain>
      <Container>
        <Grid>
          <GridItem mobileL={10} mobileLStart={2} tabletL={6} tabletLStart={4}>
            <Spacer size={[40, 80]} />

            <AnimateSlideIn>
              <Heading4 bold>{intro}</Heading4>
            </AnimateSlideIn>

            <Spacer size={[20, 40]} />

            <ContactForm
              ref={$form}
              name={formName}
              method="POST"
              data-netlify="true"
              onSubmit={handleSubmit}
            >
              <input type="hidden" name="form-name" value={formName} />
              <input
                type="hidden"
                name="subject"
                value={`${subjectPrefix} ${name}`}
              />

              {/* <AnimateSlideIn>
                <Select
                  id="form-type"
                  name={typeName}
                  value={type}
                  onChange={e => setType(e.currentTarget.value)}
                  label={
                    <TextBody>
                      <strong>Type of enquiry</strong> (please select)
                    </TextBody>
                  }
                  options={formTypeOptions}
                />
              </AnimateSlideIn>

              <Spacer size={[20, 40]} /> */}

              <AnimateSlideIn>
                <Input
                  id="form-name"
                  type="text"
                  name={nameName}
                  value={name}
                  onChange={e => setName(e.currentTarget.value)}
                  label={<TextBody bold>Name</TextBody>}
                  required
                />
              </AnimateSlideIn>

              <Spacer size={[20, 40]} />

              <AnimateSlideIn>
                <Input
                  id="form-email"
                  type="email"
                  name={emailName}
                  value={email}
                  onChange={e => setEmail(e.currentTarget.value)}
                  label={<TextBody bold>Email address</TextBody>}
                  required
                />
              </AnimateSlideIn>

              <Spacer size={[20, 40]} />

              <AnimateSlideIn>
                <Input
                  id="form-number"
                  type="text"
                  name={numberName}
                  value={number}
                  onChange={e => setNumber(e.currentTarget.value)}
                  label={
                    <TextBody>
                      <strong>Phone number</strong> (optional)
                    </TextBody>
                  }
                />
              </AnimateSlideIn>

              <Spacer size={[20, 40]} />

              <AnimateSlideIn>
                <Textarea
                  id="form-message"
                  name={messageName}
                  value={message}
                  onChange={e => setMessage(e.currentTarget.value)}
                  label={<TextBody bold>Your message</TextBody>}
                  required
                />
              </AnimateSlideIn>

              <Spacer size={[20, 40]} />

              <AnimateSlideIn>
                <Button
                  variant="tertiary"
                  onClick={() => {}}
                  iconRight={<IconArrowRight fill={colors.yellow} />}
                >
                  Send message
                </Button>
              </AnimateSlideIn>

              {formSent && (
                <>
                  <Spacer size={16} />
                  <Heading4>Your message has been sent!</Heading4>
                </>
              )}
            </ContactForm>

            <Spacer size={[44, 88]} />
          </GridItem>
        </Grid>
      </Container>
    </ContactMain>
  )
}

Contact.propTypes = {
  intro: PropTypes.string,
}

export default Contact
