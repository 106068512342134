import React from 'react'
import PropTypes from 'prop-types'
import { TextareaLabel, TextareaMain, TextareaWrapper } from './index.style'
import Spacer from '@components/Spacer'

const Textarea = ({
  type,
  value,
  onChange,
  label,
  hideLabel,
  id,
  autocomplete = 'off',
  rows = '5',
  name,
  placeholder,
  required = false,
}) => {
  return (
    <TextareaWrapper>
      <TextareaLabel htmlFor={id} hideLabel={hideLabel}>
        {label}
      </TextareaLabel>

      {!hideLabel && <Spacer size={8} />}

      <TextareaMain
        type={type}
        id={id}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        autocomplete={autocomplete}
        rows={rows}
        required={required}
      />
    </TextareaWrapper>
  )
}

Textarea.propTypes = {
  label: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  autocomplete: PropTypes.string,
  rows: PropTypes.string,
  hideLabel: PropTypes.bool,
  required: PropTypes.bool,
}

export default Textarea
